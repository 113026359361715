:root {
  --main-color: #545766;
  --primary-color: #2960ec;
  --dark-text-color: #141519;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
  color: var(--main-color);
}

/*Regular (normal): 400
  Medium: 500
  SemiBold: 600
  Bold: 700*/
@font-face {
  /* fw-400 */
  font-family: "NotoSans-regular";
  src: url("../src/assets/fonts/NotoSans-Regular.ttf");
}
@font-face {
  /* fw-500 */
  font-family: "NotoSans-medium";
  src: url("../src/assets/fonts/NotoSans-Medium.ttf");
}
@font-face {
  /* fw-600 */
  font-family: "NotoSans-semiBold";
  src: url("../src/assets/fonts/NotoSans-SemiBold.ttf");
}
@font-face {
  /* fw-700 */
  font-family: "NotoSans-bold";
  src: url("../src/assets/fonts/NotoSans-Bold.ttf");
}
/* === utility classes for font family ===*/
.fw-400 {
  font-family: "NotoSans-regular";
}
.fw-500 {
  font-family: "NotoSans-medium";
}
.fw-600 {
  font-family: "NotoSans-semiBold";
}

.fw-700 {
  font-family: "NotoSans-bold";
}
p,
span {
  font-family: "NotoSans-regular";
}

.pt-26 {
  padding-top: 26px;
}

/* .table > :not(caption) {
  padding: 0.5rem 0.54rem !important;
} */
.blur-back-drop {
  filter: blur(15px) !important;
}
.common-box-shadow {
  box-shadow: 7px 10px 84.9000015258789px 0px #00000026;
}

p,
label,
thead,
tbody,
td,
tr,
th {
  color: var(--main-color);
}
th,
td {
  font-size: 16px;
}
p {
  margin: 0;
  padding: 0;
}
.Toastify__toast-container {
  z-index: 999999999999 !important;
}
@media (min-width: 992px) {
  .container {
    max-width: 950px !important;
  }
}
.container {
  max-width: 700px !important;
}
@media print {
  .common-box-shadow {
    box-shadow: none;
  }
  .print-content {
    display: block !important;
    width: 100%;
    visibility: visible !important;
    display: block !important;
    width: 210mm; /* Width of A4 page */
    height: 297mm; /* Height of A4 page */
    margin: 0 auto;
    margin-top: 2rem;
    box-shadow: none !important;
  }
}

@media print {
  @page {
    size: 297mm 297mm;
  }
}

@media print {
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
